<!--
 * @Description: Description
 * @Author: dpw
 * @Date: 2025-01-216 11:46:23
 * @LastEditors: liutq
 * @LastEditTime: 2025-01-216 11:46:23
-->
<template>
    <div :class="['register-page', $i18n.locale]">
        <div class="navbar">
            <el-dropdown placement="bottom" class="custom-dropdown" @command="onCommand" trigger="click">
                <span class="el-dropdown-language">{{ currentLanguage }} </span>
                <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
                    <el-dropdown-item :command="item.label" v-for="(item, index) in languages" :key="index"
                        :disabled="item.label === currentLanguage">{{ item.label }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="header">
            <h2>{{ $t('login.account.accountTitle') }}</h2>
            <p>{{ $t('login.title') }}</p>
            <!-- <p class="tips">{{ $t('login.btn.title') }}</p> -->
        </div>
        <!-- 登录选择 -->
        <div class="login" :style="userType ? 'justify-content: center;' : ''">
            <div @click="selectMobile" v-if="!(userType == '2')">
                <span
                    :class="[qkStatus ? (loginStatus ? 'mobile-qk1' : 'mobile-qk') : { 'mobile-login': loginStatus }]">{{
                        $t('login.mobileLogin') }}</span>
            </div>
            <span class="icon" v-if="!userType">|</span>
            <div @click="selectEmail" v-if="!(userType == '1')">
                <span
                    :class="[qkStatus ? (!loginStatus ? 'mobile-qk1' : 'mobile-qk') : { 'mobile-login': !loginStatus }]">{{
                        $t('login.emailLogin') }}</span>
            </div>
        </div>
        <!-- 手机号绑定页面 -->
        <div class="content" v-if="loginStatus">
            <div class="form">
                <div class="item">
                    <label for="mobile" @click="selectAreaCode">
                        <div class="area">
                            <span class="no">{{ areaCode }}</span>
                            <i class="arrow_bottom"></i>
                        </div>
                    </label>
                    <div class="info">
                        <van-field autocomplete="off" @blur="handleBlurReset($event)" @focus="handleFouceReset($event)"
                            label="" name="mobile" type="digit" v-model.trim="form.phone"
                            :placeholder="$t('login.formList[0].label')" :border="false" />
                    </div>
                </div>
                <div class="item">
                    <label class="codeLabel codeLabel-item" for="verifyPassword">{{
                        $t('login.account.password')
                    }}</label>
                    <div class="info">
                        <input :style="$i18n.locale === 'zh-CN' || $i18n.locale === 'zh-TW' || $i18n.locale === 'zh-HK'
                            ? 'padding-left: 50px'
                            : $i18n.locale === 'es-ES' || $i18n.locale === 'it-IT' || $i18n.locale === 'tr-TR'
                                ? 'padding-left: 10px'
                                : 'padding-left: 10px'
                            " autocomplete="off" ref="verifyPassword" v-model="form.password"
                            @blur="handleBlurReset($event)" @focus="handleFouceReset($event)" @input="validatorPassword"
                            :type="isAppleDevice ? (showPassword ? 'text' : 'password') : 'text'" label=""
                            :placeholder="$t('login.account.placeholder')" name="verifyPassword" :border="false"
                            :class="isAppleDevice ? '' : showPassword ? 'password-visible' : 'password-hidden'" />
                        <span class="toggle-password" @click="togglePassword">
                            <img :src="showPassword
                                ? require('@/assets/images/eye_show.png')
                                : require('@/assets/images/eye_off.png')
                                " class="eye-icon" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 邮箱登录页面 -->
        <div class="content" v-else>
            <div class="form">
                <div class="item">
                    <label for="email" v-if="$i18n.locale === 'tr-TR' || $i18n.locale === 'ar-AR'">{{
                        $t('login.email')
                    }}</label>
                    <label for="email" v-else>E-mail</label>
                    <div class="info">
                        <van-field @blur="handleBlurReset($event)" @focus="handleFouceReset($event)"
                            v-model.trim="form.email" name="" label="" :placeholder="$t('login.formList[2].label')"
                            class="iteminput" />
                    </div>
                </div>
                <div class="item">
                    <label for="verifyPassword" class="codeLabel codeLabel-item">{{
                        $t('login.account.password')
                    }}</label>
                    <div class="info">
                        <input :style="$i18n.locale === 'zh-CN'
                            ? 'padding-left: 50px'
                            : $i18n.locale === 'es-ES' || $i18n.locale === 'it-IT' || $i18n.locale === 'tr-TR'
                                ? 'padding-left: 10px'
                                : 'padding-left: 20px'
                            " autocomplete="off" ref="verifyPassword" v-model="form.password"
                            @blur="handleBlurReset($event)" @focus="handleFouceReset($event)" @input="validatorPassword"
                            :type="isAppleDevice ? (showPassword ? 'text' : 'password') : 'text'" label=""
                            :placeholder="$t('login.account.placeholder')" name="verifyPassword" :border="false" :class="[
                                isAppleDevice ? '' : showPassword ? 'password-visible' : 'password-hidden',
                                'itemyzm',
                            ]" />

                        <span class="toggle-password" @click="togglePassword">
                            <img :src="showPassword
                                ? require('@/assets/images/eye_show.png')
                                : require('@/assets/images/eye_off.png')
                                " class="eye-icon" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <div class="footer">
            <div class="login-tool">
                <div class="rule" @click="isAutoLogin = !isAutoLogin">
                    <span :class="[{ 'is-check': isAutoLogin }, 'checkbox']">
                        <input type="checkbox" v-model="isAutoLogin" />
                    </span>
                    <i>{{ $t('login.autoLogin') }}</i>
                </div>
                <div @click="forgotPassword">
                    <span>{{ $t('login.account.forgotPassword') }}</span>
                </div>
            </div>

            <div class="submit-btn">
                <div class="error-tips" v-if="erorTips">{{ erorTips }}</div>
                <van-button :disabled="isMobileSubmitDisabled" :class="{
                    'is-disabled': isMobileSubmitDisabled,
                    'custom-btn': true,
                }" @click.prevent="handleMobileSumbit" @keyup.enter="handleMobileSumbit" type="primary"
                    color="linear-gradient(90deg, #00E3C9 0%, #009FE8 100%)">{{ $t('login.account.accountTitle') }}
                </van-button>
            </div>
        </div>
        <!-- <p class="tips">{{ $t('login.btn.title') }}</p> -->
        <area-code v-if="showAreaCodeSelect" @selectCode="onSelect" class="areaCode" />

        <div class="bottom-register">
            <div @click="codeLogin">
                <span class="right-text">{{ $t('login.account.codeLogin') }}</span>
            </div>
            <span>｜</span>
            <div @click="registerClick">
                <span class="right-text">{{ $t('login.account.registerTips[1]') }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { accountLogin } from '@/assets/js/apolloGql.js'
import { cryptographicCheck, singleCryptographicCheck, encryptPassword } from '@/assets/js/util.js'
import { shareTime } from '@/assets/js/config.js'
import AreaCode from '@/components/user/AreaCode.vue'
import { languageConfig, languageConfigM60 } from '@/assets/js/langObject'

export default {
    components: {
        AreaCode,
    },
    data() {
        return {
            isFocus: false,
            erorTips: '',
            iphoneResult: '',
            isAutoLogin: false,
            areaCode: '',
            showAreaCodeSelect: false,
            currentLanguage: '',
            languages: JSON.parse(window.localStorage.getItem('deviceType')) == 4 ? languageConfigM60 : languageConfig,
            form: {
                code: '',
                phone: '',
                email: '',
            },
            height: {
                isValid: false,
                value: '',
            },
            birthday: {
                isValid: false,
                year: '',
                month: '',
                day: '',
            },
            codeText: '',
            outTimeStamp: 0,
            // 错误信息
            errorMg: '-',
            // 提交按钮状态
            submitDisabled: false,
            // 按钮开关
            isBtnHand: true,
            loginInfo: JSON.parse(window.localStorage.getItem('loginInfo')),
            // 选择登录状态
            loginStatus: true,
            timeTag: null,
            qkStatus: false,
            // 登录按钮是否正在加载中
            loginBtnState: false,
            userId: '',
            userType: 0, // egym 0 代表，当前无手机号邮箱   1   手机号   2 邮箱
            showPassword: false, // 用于控制密码显示/隐藏
        }
    },

    computed: {
        isAppleDevice() {
            return /Mac|iPhone|iPad|iPod/i.test(navigator.userAgent)
        },
        isCodeBtnDisabledEmail() {
            return !this.form.email
        },
        // 是否禁用登录按钮
        isMobileSubmitDisabled() {
            return !cryptographicCheck(this.form.password)
        },
        // 是否禁用用户信息注册按钮
        isSubmitDisabled() {
            return this.birthday.isValid === false || this.height.isValid === false
        },
        userAgent() {
            const ua = navigator.userAgent
            return {
                ua: ua,
                isAndroid: ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1,
                isiOS: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
            }
        },
    },

    watch: {
        // <p>您的验证码为<%=code%>，欢迎使用维塑三维体测精灵，请在10分钟内输入。</p>
        // <p>Il tuo codice di verifica è <%=code%>, il codice è valido per 10 minuti. Goditi la tua esperienza di scansione corporea 3D Visbody!</p>
    },

    beforeDestroy() {
        clearInterval(this.timer)
    },
    created() {
        // 分享链接处理
        let url = window.location.href
        const arrUrl = url.split('&')
        localStorage.setItem('share', true)
        if (arrUrl.length > 3) {
            sessionStorage.clear()
            localStorage.clear()
            // 7天分享链接为有
            let index = url.includes('type') ? 1 : 0
            const flag = Date.now() - arrUrl[3 + index].split('-')[0]

            if (flag < parseInt(shareTime) * 60 * 60 * 1000) {
                // console.log(arrUrl[0].split('?')[1], 88888, url.includes('type'))
                sessionStorage.setItem('token', index ? arrUrl[1] : arrUrl[0].split('?')[1])
                localStorage.setItem('unit', arrUrl[1 + index])
                localStorage.setItem('lang', arrUrl[2 + index])
                localStorage.setItem('deviceType', arrUrl[3 + index].split('-')[1])
                localStorage.setItem('share', false)
                this.$i18n.locale = arrUrl[2 + index]
                // 历史分享scanId
                // console.log(arrUrl[5])
                if (index) localStorage.setItem('shareScanId', arrUrl[5])

                this.$router.push({
                    path: url.includes('type') ? '/report?type=1' : '/report',
                })
            }
        }
        var userAgent = navigator.userAgent
        if (userAgent.indexOf('Quark') > -1) {
            this.qkStatus = true
        }
        // 单位默认值为公制单位
        const unit = localStorage.getItem('unit')
        if (!unit) {
            localStorage.setItem('unit', 'metric')
        }
        // 默认语言配置
        const lang = localStorage.getItem('lang')
        if (!lang) {
            localStorage.setItem('lang', 'en-US')
        }

        console.log(`语言配置${JSON.stringify(languageConfig)}`)
        this.codeText = this.$t('login.formList[0].placeholder')
        this.areaCode = this.languages.find((item) => item.value === this.$i18n.locale)?.no
        this.currentLanguage = this.languages.find((item) => item.value === this.$i18n.locale)?.label
        const register = this.$route.query.register
        if (register) {
            this.toRegister()
        }
        // egym开发流程
        const urlObj = new URL(url)
        const redirectParam = urlObj.searchParams.get('redirect')
        if (redirectParam) {
            // 解码嵌套参数
            const nestedParams = new URLSearchParams(redirectParam.split('?')[1])
            this.userType = parseInt(nestedParams.get('user_type'))
            this.userId = nestedParams.get('user_id')
            console.log(this.userType, this.userId, 88888)
            if (this.userId && this.userId.length > 0) {
                // egym 客户登录使用验证码登录
                this.codeLogin()
            }
        }
    },
    mounted() {
        // 保留信息不被清空
        const lookUserInfo = JSON.parse(window.localStorage.getItem('lookUserInfo'))
        if (lookUserInfo) {
            this.form.phone = lookUserInfo.phone
            this.form.code = lookUserInfo.code
            this.form.email = lookUserInfo.email
        }

        // egym进行登录选择
        switch (this.userType) {
            case 1:
                this.selectMobile()
                break
            case 2:
                this.selectEmail()
                break
            default:
                break
        }
    },

    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        // 选择手机号
        selectMobile() {
            this.form.code = ''
            this.loginStatus = true
            if (this.loginStatus) {
                this.form.email = ''
            } else {
                this.form.phone = ''
            }
            if (this.form.password) {
                this.form.password = ''
            }
            this.erorTips = ''
        },
        // 选择邮箱
        selectEmail() {
            this.form.code = ''
            if (this.form.phone) {
                this.form.phone = ''
            }
            if (this.form.password) {
                this.form.password = ''
            }
            this.loginStatus = false
        },
        // 校验密 码
        validatorPassword() {
            this.form.password = event.target.value.replace(/[\u4e00-\u9fa5]/g, '')
            if (this.form.password.length < 4) {
                this.erorTips = ''
                return
            }
            const data = singleCryptographicCheck(this.form.password)
            if (!data.length) {
                this.erorTips = this.$t('login.account.checkRule[0]')
            } else if (!data.uppercase) {
                this.erorTips = this.$t('login.account.checkRule[1]')
            } else if (!data.lowercase) {
                this.erorTips = this.$t('login.account.checkRule[2]')
            } else if (!data.digit) {
                this.erorTips = this.$t('login.account.checkRule[3]')
            } else {
                this.erorTips = ''
            }
        },
        // 选择地区国家
        selectAreaCode() {
            this.showAreaCodeSelect = true
        },
        onSelect(code) {
            if (code) this.areaCode = code
            this.showAreaCodeSelect = false
        },

        onCommand(lang) {
            // console.log(lang);
            // console.log(this.languages);
            let languageInfo = {}
            for (let index = 0; index < this.languages.length; index++) {
                if (lang === this.languages[index].label) {
                    languageInfo = this.languages[index]
                    // console.log(languageInfo);
                    break
                }
            }
            this.$i18n.locale = languageInfo.value
            this.areaCode = languageInfo?.no
            localStorage.setItem('lang', this.$i18n.locale)
            this.currentLanguage = lang
            this.codeText = this.$t('login.formList[0].placeholder')
            this.erorTips = ''
            this.form.phone = ''
            this.form.code = ''
            this.form.email = ''
            this.form.password = ''
        },
        // 失去焦点后重置背景
        handleBlurReset(e) {
            this.resetBackground()
            const phone = this.form.phone
            const email = this.form.email
            if (phone === '' || email === '') {
                if (this.loginStatus && phone === '') {
                    this.$toast({
                        message: this.$t('login.formList[0].label'),
                        duration: 1500,
                    })
                    return
                } else if (!this.loginStatus && email === '') {
                    this.$toast({
                        message: this.$t('login.formList[2].label'),
                        duration: 1500,
                    })
                    return
                }
            }
            if (this.loginStatus) {
                if (phone.length < 5 || phone.length > 15) {
                    this.erorTips = this.$t('login.rule.phone.requiredMsg')
                } else {
                    this.erorTips = ''
                }
            } else {
                // eslint-disable-next-line no-useless-escape
                const email =
                    // eslint-disable-next-line no-useless-escape
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!email.test(this.form.email)) {
                    this.erorTips = this.$t('login.rule.email.requiredMsg')
                } else {
                    this.erorTips = ''
                }
            }

            if (this.form.password === '') {
                this.isFocus = false
            }
            this.outTimeStamp = e.timeStamp
            setTimeout(() => {
                if (this.outTimeStamp === e.timeStamp) {
                    this.resetBackground()
                }
            }, 100)
        },

        handleFouceReset(e) {
            this.outTimeStamp = e.timeStamp
        },

        // 重置iso下键盘收回后底部空白问题
        resetBackground() {
            window.scrollTo(0, 0)
        },
        // 跳转注册页面
        jumpRegis() {
            const setTimer = setTimeout(() => {
                this.toRegister()
                window.clearTimeout(setTimer)
            }, 1000 * 1)
        },
        // 清空手机号
        handleClearMobile() {
            if (this.form.phone) {
                this.form.phone = ''
                this.form.code = ''
                window.localStorage.removeItem('lookUserInfo')
            } else {
                this.form.email = ''
                this.form.code = ''
                window.localStorage.removeItem('lookUserInfo')
            }
        },
        validateForm() {
            let err

            if (!this.form.password) {
                err = this.$t('login.formList[1].placeholder')
            }
            if (this.form.password) {
                let data = singleCryptographicCheck(this.form.password)
                if (!data.length) {
                    err = this.$t('login.account.checkRule[0]')
                }
                if (!data.uppercase) {
                    err = this.$t('login.account.checkRule[1]')
                }
                if (!data.lowercase) {
                    err = this.$t('login.account.checkRule[2]')
                }
                if (!data.digit) {
                    err = this.$t('login.account.checkRule[3]')
                }
            }
            if (!this.form.phone && !this.form.email) {
                if (this.loginStatus) {
                    err = this.$t('login.formList[0].label')
                } else {
                    err = this.$t('login.formList[2].label')
                }
            } else {
                if (this.loginStatus) {
                    const reg = /^[0-9]*$/
                    if (!reg.test(this.form.phone)) {
                        err = this.$t('login.rule.phone.requiredMsg')
                    }
                } else {
                    // eslint-disable-next-line no-useless-escape
                    const email =
                        // eslint-disable-next-line no-useless-escape
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (!email.test(this.form.email)) {
                        err = this.$t('login.rule.email.requiredMsg')
                    }
                }
            }
            if (err) {
                this.erorTips = err
                return false
            }
            return true
        },
        // 用户登录
        handleMobileSumbit() {
            if (!this.isMobileSubmitDisabled && this.validateForm() && !this.loginBtnState) {
                this.loginBtnState = true
                let mobile = ''
                if (this.form.phone) {
                    mobile = this.areaCode + this.form.phone
                }
                this.$apollo
                    .query({
                        query: accountLogin,
                        fetchPolicy: 'network-only',
                        variables: {
                            mobile: mobile,
                            email: this.form.email,
                            password: encryptPassword(this.form.password),
                        },
                    })
                    .then((res) => {
                        const data = res.data.accountLogin
                        console.log(data)
                        // 老用户 已有用户信息
                        if (data.code === 200) {
                            window.localStorage.removeItem('modelInfo')
                            window.localStorage.removeItem('lookUserInfo')
                            window.localStorage.setItem(
                                'loginInfo',
                                JSON.stringify({
                                    mobile: mobile,
                                    email: this.form.email,
                                })
                            )
                            // 保存token
                            sessionStorage.setItem('token', data.data.token)
                            // 下次自动登录
                            if (this.isAutoLogin) {
                                // 设置自动登录
                                localStorage.setItem('token', data.data.token)
                            }
                            if (!this.loginStatus) {
                                localStorage.setItem('email', this.form.email)
                            } else {
                                localStorage.removeItem('email')
                            }
                            this.$toast({
                                message: this.$t('login.loginSuccess'),
                                duration: 1500,
                            })
                            setTimeout(() => {
                                this.loginBtnState = false
                                // 默认跳转到报告页面
                                let path = '/report'
                                // 如果是从别的页面跳转过来的，跳到之前的页面
                                if (this.$route.query.redirect) {
                                    path = this.$route.query.redirect // 跳到之前的页面
                                }
                                this.$router.push({
                                    path: path,
                                })
                            }, 2000)
                        } else if (data.code === 403) {
                            // 密码错误
                            console.log(`${data.message}`)
                            this.loginBtnState = false
                            this.erorTips = this.$t('login.account.errorTips[0]')
                        } else if (data.code === 404) {
                            // 没有注册 账号不存在
                            console.log(`${data.message}`)
                            this.loginBtnState = false
                            this.erorTips = this.$t('login.account.errorTips[2]')
                        } else {
                            this.loginBtnState = false
                            this.erorTips = this.$t('login.account.errorTips[1]')
                        }
                    })
                    .catch(() => {
                        this.loginBtnState = false
                    })
            }
        },

        // 判断验证码长度
        validatorCode() {
            const code = this.form.code.replace(/\s*/g, '')
            if (code.length > 4) {
                this.form.code = code.substring(0, 4)
                // 直接截取，不提示
                // this.$toast({
                //     message: this.$t('register.verify'),
                //     duration: 2000,
                // })
            }
        },
        // 去注册页面
        toRegister() {
            if (!this.form.phone && !this.form.email) {
                var err = ''
                if (!this.form.phone) {
                    err = this.$t('login.formList[0].label')
                } else {
                    err = this.$t('login.formList[2].label')
                }
                this.erorTips = err
                return
            }
            const userInfo = JSON.stringify({
                phone: this.form.phone,
                code: this.form.code,
                email: this.form.email,
                areaCode: this.areaCode,
            })
            window.localStorage.setItem('lookUserInfo', userInfo)
            console.log(`开始存储 ${userInfo}`)
            // const phone = this.areaCode + this.form.phone
            this.$router.push({
                path: '/register',
                query: { redirect: this.$route.query.redirect || '/exception/report' }, // 'to.fullPath'跳转到登录之前页面的路径
            })
        },
        // 使用验证码登录
        codeLogin() {
            this.$router.push({
                path: '/codeLogin',
                query: this.$route.query, // 'to.fullPath'跳转到登录之前页面的路径
            })
        },
        // 忘记密码
        forgotPassword() {
            this.$router.push({
                path: '/resetPassword',
                query: { redirect: this.$route.query.redirect || '/exception/report', type: 'forgotPassword' }, // 'to.fullPath'跳转到登录之前页面的路径
            })
        },
        registerClick() {
            this.$router.push({
                path: '/codeLogin',
                query: this.$route.query, // 'to.fullPath'跳转到登录之前页面的路径
            })
            // this.$router.push({
            //     path: '/registerAccount',
            //     query: { redirect: this.$route.query.redirect || '/exception/report' }  // 'to.fullPath'跳转到登录之前页面的路径
            // });
        },
    },
}
</script>

<style scoped lang="less">
.register-page {
    flex: 1;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    background-image: linear-gradient(180deg, #262849, #07090f);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // justify-content: space-between;
    justify-content: initial;

    .areaCode {
        z-index: 999;
    }

    .navbar {
        width: 100%;
        height: 30px;
        padding-right: 45px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .back {
            font-size: 12px;
            padding-left: 21px;
            color: #fff;
        }

        /deep/ .van-icon-arrow-left:before {
            font-size: 14px;
            color: #fff;
        }
    }

    .el-dropdown-language {
        font-size: 12px;
        color: #fff;

        &::after {
            content: '';
            position: absolute;
            top: 55%;
            right: -14px;
            transform: translateY(-80%);
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #fff;
        }
    }

    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        // padding-bottom: 38.5px;

        h2 {
            // width: 84px;
            height: 30px;
            mix-blend-mode: initial;
            color: #ffffff;
            // background-image: linear-gradient(to right, #00e3c9, #00a4e6), linear-gradient(#ffffff, #ffffff);
            font-family: PingFangSC;
            font-size: 21px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: center;
            -webkit-background-clip: text;
            background-clip: text;
            // -webkit-text-fill-color: transparent;
        }

        p {
            width: 168px;
            height: 34px;
            font-family: PingFangSC;
            mix-blend-mode: initial;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #8aa3be;
            margin-top: 8px;
            margin-bottom: 34px;
        }
    }

    .login {
        display: flex;
        justify-content: space-between;
        width: 172px;
        font-size: 15px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #8aa3be;
        line-height: 15px;
        margin-bottom: 43px;

        .icon {
            width: 1px;
            height: 19px;
            color: #373955;
        }

        .mobile-login {
            width: 60px;
            height: 15px;
            font-size: 15px;
            font-family: PingFangSC;
            font-weight: bold;
            color: #8aa3be;
            line-height: 15px;
            padding-bottom: 15px;
            border-bottom: 2px solid #009fe8;
            background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .mobile-qk {
            width: 60px;
            height: 15px;
            font-size: 15px;
            font-family: PingFangSC;
            font-weight: bold;
            color: #8aa3be;
            line-height: 15px;
            padding-bottom: 15px;
            // color:#009FE8!important;
        }

        .mobile-qk1 {
            position: relative;
            color: #009fe8 !important;

            &:before {
                content: ' ';
                display: inline-block;
                position: absolute;
                top: 25px;
                left: 28%;
                width: 50%;
                border-bottom: 2px solid #009fe8;
            }
        }
    }

    .bottom-register {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 12px;
        font-family: PingFangSC;
        color: #4a90e2;
        line-height: 15px;
        margin-top: 30px;

        .right-text {
            font-size: 12px;
            font-family: PingFangSC;
        }
    }

    /deep/ .content .form .item {
        input:-internal-autofill-previewed,
        input:-internal-autofill-selected {
            -webkit-text-fill-color: #ffffff !important;
            transition: background-color 5000s ease-in-out 0s !important;
        }
    }

    .content {

        // flex: 1;
        // 表单
        .form {
            width: 100%;
            height: auto;

            .item {
                width: 345px;
                height: 56px;
                margin: 0 auto;
                border-radius: 4px;
                // background-color: #232440;
                background: #303c5c;
                padding: 0 16px;
                color: rgba(255, 255, 255, 1);
                margin-bottom: 8px;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.24);
                display: flex;
                align-items: center;
                box-sizing: border-box;

                .area {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .no {
                        margin-right: 7px;
                    }

                    .arrow_bottom {
                        width: 0;
                        height: 0;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-top: 6px solid #fff;
                    }
                }

                .info {
                    position: relative;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    /deep/ .van-field {
                        padding-left: 0;
                        // background-color: #232440;
                        background: #303c5c;
                    }

                    /deep/ .van-field__control {
                        color: #fff;
                        margin-left: 30px;
                        padding-left: 0px;
                    }

                    /deep/ .van-field__control::placeholder {
                        color: #6c6c6f;
                        font-size: 13.5px;
                    }

                    &.center {
                        justify-content: center;
                    }

                    .over {
                        position: absolute;
                        z-index: 10;
                        width: 100%;
                        height: 56px;
                        font-size: 14px;
                        color: rgba(255, 255, 255, 0.5);
                        line-height: 56px;
                        // pointer-events: none;
                        background-color: #303c5c;
                    }

                    .toggle-password {
                        .eye-icon {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                label {
                    font-size: 14px;
                    text-align: left;
                }

                input {
                    width: 100%;
                    height: 56px;
                    background: transparent;
                    // padding-left: 46px;
                    font-size: 14px;
                    color: #fff;

                    &[name='birthday'] {
                        flex: none;
                        width: 50px;
                    }
                }

                .password-visible {
                    color: #fff;
                }

                .password-hidden {
                    //解决小米安全键盘回收留白底（防止调用安全键盘使用‘text’）
                    -webkit-text-security: disc;
                    text-security: disc;
                }

                /deep/ .van-field__control {
                    margin-left: 46px;
                }

                input[name='height']~span {
                    font-size: 12px;
                    line-height: 12px;
                    color: rgba(255, 255, 255, 0.5);
                }

                .sex {
                    display: inline-block;
                    opacity: 0.5;
                    width: 40px;
                    height: 24px;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    margin: 0 20px;
                    border-radius: 4px;
                    border: 1px solid rgba(255, 255, 255, 1);

                    &.is-active {
                        opacity: 1;
                    }
                }

                .get-code-btn {
                    min-width: fit-content;
                    font-size: 12px;
                    color: #fff;

                    &.is-disabled {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .footer {
        .login-tool {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #4a90e2;
            line-height: 15px;
            margin-top: 10px;
            padding: 0 15px 0 15px;

            .rule {
                color: #fff;
                height: auto;
                font-size: 12px;
                display: flex;
                align-items: center;

                i {
                    font-style: normal;
                }

                .checkbox {
                    position: relative;
                    height: 14px;
                    width: 14px;
                    border-radius: 50%;
                    margin-right: 5px;
                    border: 1px solid #fff;

                    input {
                        height: 12px;
                        width: 12px;
                        border-radius: 50%;
                        opacity: 0;
                    }

                    &.is-check {
                        &:before {
                            position: relative;
                            content: '';
                            height: 14px;
                            width: 14px;
                            margin: -1px;
                            border-radius: 50%;
                            // background: #00a4e6;
                            display: block;
                            overflow: hidden;
                            background: url('../../assets/images/comp_rb.png') no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }

                span {
                    color: #4a90e2;
                }
            }
        }

        .opacity {
            opacity: 0;
        }

        // 提交按钮
        .submit-btn {
            position: relative;
            margin-top: 32px;

            .error-tips {
                position: absolute;
                top: -25px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 12px;
                color: #fe6363;
            }

            .create-account {
                text-align: center;
                font-size: 14px;
                color: #4a90e2;
                margin-top: 16px;
            }

            button {
                width: 200px;
                height: 40px;
                outline: none;
                border-radius: 6px;
                background-image: -webkit-gradient(linear, left top, right top, from(#00e3c9), to(#009fe8));
                background-image: -webkit-linear-gradient(left, #00e3c9, #009fe8);
                background-image: -o-linear-gradient(left, #00e3c9, #009fe8);
                background-image: linear-gradient(to right, #00e3c9, #009fe8);
                font-size: 16px;
                color: #fff;

                &.is-disabled {
                    border: 1px solid transparent;
                    background-image: linear-gradient(#191b31, #191b31),
                        /* 底色，即原有的背景 */
                        linear-gradient(to right, #06b0a2, #067eb9);
                    /* 模拟渐变边框 */
                    background-clip: padding-box, border-box;
                    background-origin: border-box;
                }

                .spinnerBtn {
                    display: flex;
                    justify-content: center;
                }

                /deep/ .mint-spinner-snake {
                    border: 2px solid transparent;
                }

                &.custom-btn {
                    width: 345px;
                    height: 40px;
                    background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
                    border-radius: 6px;
                    margin-top: 20px;
                }
            }
        }
    }

    .icon-del {
        float: right;
        width: 9.5px;
        height: 9.5px;
        border-radius: 0.8px;
        background: url('../../assets/images/register/register_phone_delete.png') no-repeat;
    }

    .tips {
        position: relative;
        display: inline-block;
        font-size: 12px;
        height: 12px;
        line-height: 12px;
        min-width: 10px;
        color: #ffffff;
        margin-bottom: 30px;

        &::before {
            content: '*';
            position: absolute;
            width: 5px;
            left: -8px;
            top: 2px;
        }
    }

    //  /deep/ .item:nth-child(2){
    //      .info{
    //         .van-field__control{
    //             margin-left: 50px;
    //         }
    //      }
    //     }

    &.ja-JP {
        .header p {
            width: 225px;
            line-height: 17px;
        }

        .header {
            h2 {
                width: 168px;
            }
        }

        /deep/ .content .form .item {
            .get-code-btn {
                min-width: 60px;
                text-align: left;
            }
        }

        /deep/ .item:nth-child(2) {
            .van-field__control {
                margin-left: 22px;
            }
        }

        /deep/ input[name='verifyPassword'] {
            width: 104px;
            line-height: 20px;
            white-space: normal;
            word-break: break-all;
            word-wrap: break-word;
        }

        div .error-tips {
            width: 314px;
            height: 34px;
        }

        /deep/ .van-cell__value {
            margin-left: 0px;
            text-align: left;
        }

        .custom-btn {
            margin-top: 10px !important;
        }
    }

    &.en-US,
    &.it-IT,
    &.fr-FR,
    &.ru-RU,
    &.es-ES,
    &.pt-PT,
    &.tr-TR,
    &.de-DE {
        .header p {
            width: 312px;
            line-height: 17px;
        }

        .checkbox {
            border: 1px solid #8aa3be !important;
        }

        i {
            color: #8aa3be;
        }

        .submit-btn {
            .error-tips {
                width: 300px;
            }
        }
    }

    &.es-ES,
    &.pt-PT {
        .login {
            width: 222px;
        }

        .codeLabel {
            width: 70px;
        }
    }

    &.pt-PTO {
        .codeLabel-item {
            width: 80px;
        }
    }

    &.ar-AR {
        direction: rtl;

        .iteminput {
            direction: ltr;
        }

        .itemyzm {
            direction: ltr;
            text-align: right;
        }

        .header {
            p {
                text-align: right;
            }
        }

        .search {
            padding-right: 10px;
        }

        /deep/.el-dropdown {
            margin-left: 20px;
        }

        /deep/.van-field__control {
            text-align: right;
        }

        .content .form .item input,
        .footer .rule i,
        .content .form .item .area .arrow_bottom {
            margin-right: 10px;
        }

        .item .info .van-field__body {
            direction: ltr;
        }
    }
}
</style>
<style>
/deep/.register-page {
    background-image: linear-gradient(180deg, #262849, #07090f) !important;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #303c5c inset;
    -webkit-text-fill-color: #fff;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected {
    -webkit-text-fill-color: #303c5c;
    transition: background-color 5000s ease-out 0.5s;
}

::-webkit-input-placeholder {
    color: #6c6c6f;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #6c6c6f;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #6c6c6f;
}

:-moz-placeholder {
    color: #6c6c6f;
}
</style>
